import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { useInView } from "react-intersection-observer"
import descriptionData from "../content/description.json"
import ProductContainer from "../components/productContainer"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const BricksTechnic = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)  

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://www.collectivebricks.com/technic" />
        <title>{descriptionData.makeup.pageTitle}</title>
        <meta name="description" content={descriptionData.makeup.pageDescription}/>        
      </Helmet>

      <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
        <h1 class="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.makeup.heading}
        </h1>
        <p className="hidden md:block">{descriptionData.makeup.description1}</p>
        <p className="text-l mt-5 mb-5 font-bold">
        Every Friday, Collective Bricks update a variety of Technic LEGO sets. Make sure to visit our website frequently to stay up-to-date on the latest additions to our collection and never miss out on any of our fantastic products.
        </p>
      </div>

      <div className="product-container grid grid-cols-2 gap-2 md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} dressType="Black" />
      </div>
      <p className="lg:hidden">{descriptionData.makeup.description1}</p>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "bricks_technic" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
            product_images_2nd
            product_images_3rd
            product_images_4th
            product_images_5th
            product_images_6th
          }
        }
      }
    }
  }
`


export default BricksTechnic
